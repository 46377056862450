<template>
  <header :class="{ hideNav: hide && hide.indexOf('nav') !== -1 }">
    <div v-is="'script'" src="https://pv.sohu.com/cityjson"></div>

    <div class="topnav">
      <div class="container">
        <a class="position" href="/">{{ pos }}</a>
        <div class="right" v-if="!username">
          <span>你好，请</span>
          <router-link to="/signin" class="signin">登录</router-link>
          <router-link to="/signup" class="blue register">注册</router-link>
          <router-link to="/center/orders">我的订单</router-link>
        </div>
        <div class="right" v-if="username">
          <router-link to="/center" class="blue account">{{
            username
          }}</router-link>
          <a @click="signout">退出登录</a>
          <router-link to="/center/orders">我的订单</router-link>
        </div>
      </div>
    </div>

    <div
      class="container"
      :class="{ hideCart: hide && hide.indexOf('shopcart') !== -1 }"
    >
      <router-link to="/home" class="logo">
        <img src="@/assets/demo/logo.png" />
      </router-link>
      <h1 v-if="subtitle">{{ subtitle }}</h1>

      <div class="search" v-if="!hide || hide.indexOf('search') === -1">
        <input
          type="text"
          name="keyword"
          v-model="keyword"
          @keyup.enter.native="search"
        />
        <button @click="search">搜索</button>
        <ul class="hot">
          <li v-for="tag in hotSearch" :key="tag.hotSearchId">
            <router-link :to="`/search?keyword=${tag.keyword}`">{{
              tag.keyword
            }}</router-link>
          </li>
        </ul>
      </div>

      <div class="shopcart" v-if="!hide || hide.indexOf('shopcart') === -1">
        <!-- 打开菜单加class: act-->
        <router-link to="/cart" class="button">
          我的购物车<small>{{ cartNum }}</small>
        </router-link>
        <div class="prolist">
          <template v-if="username && cart.length > 0">
            <h3>最新加入的商品</h3>
            <ul>
              <li v-for="(item, i) in cart" :key="item.shoppingCartId">
                <router-link
                  class="pro"
                  :to="`/goods/view/${item.goodsInfoId}`"
                >
                  <img :src="item.productMap.goodsInfoImgId" />
                  <strong>{{ item.productMap.goodsInfoName }}</strong>
                  <p>
                    {{ item.productMap.goodsInfoPreferPrice
                    }}<i>{{ item.goodsNum }}</i>
                  </p>
                </router-link>
                <a class="del" :data-i="i" @click="removeCart">删除</a>
              </li>
            </ul>
          </template>
          <div v-else class="empty">
            {{ !username ? "请先登录账号" : "还未添加任何商品" }}
          </div>
          <div class="operates">
            <div class="count">
              共<b>{{ cartNum }}</b
              >件商品
            </div>
            <router-link to="/cart" class="btn">去购物车</router-link>
          </div>
        </div>
      </div>
    </div>

    <nav class="mainnav" v-if="!hide || hide.indexOf('nav') === -1">
      <div class="container">
        <div class="sidenav" :class="{ show: showClassify }">
          <h3>商品分类</h3>
          <ul>
            <li v-for="item in classify" :key="item.cat_id">
              <router-link :to="`/search?cid=${item.cat_id}`">{{
                item.cat_name
              }}</router-link>
              <ul v-if="item.classifyBar && item.classifyBar.length">
                <li v-for="subitem in item.classifyBar" :key="subitem.cat_id">
                  <h4>
                    <router-link :to="`/search?cid=${subitem.cat_id}`">{{
                      subitem.cat_name
                    }}</router-link>
                  </h4>
                  <div class="cats" v-if="subitem.classifyBar">
                    <router-link
                      v-for="cat in subitem.classifyBar"
                      :key="cat.cat_id"
                      :to="`/search?cid=${cat.cat_id}`"
                      >{{ cat.cat_name }}</router-link
                    >
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <ul>
          <li><router-link to="/home">首页</router-link></li>
          <li><a href="">生日会</a></li>
          <li><a href="">优品百货</a></li>
          <li><a href="">精品生鲜</a></li>
          <li><a href="">企业福利</a></li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import api from "../../api/index";
import apis from "../../api/api";

export default {
  name: "homeTmpl",
  props: ["showClassify", "hide", "subtitle"],

  data() {
    return {
      username: this.$store.getters.customerName,
      pos: sessionStorage.getItem("topnav_pos") || "定位中",
      classify: null,
      hotSearch: [],
      cart: [],
      cartNum: 0,
      keyword: "",
    };
  },

  computed: {
    needUpdateCart() {
      return this.$store.getters.updateCart;
    },
  },

  watch: {
    "$store.getters.customerName": function (to) {
      this.username = to;
    },

    $route(to) {
      if (to.path === "/search" && to.query.keyword) {
        this.keyword = to.query.keyword;
      }
    },

    needUpdateCart(to) {
      if (to) {
        this.loadCart().then(() => {
          this.$store.dispatch("updatedCart");
        });
      }
    },
  },

  created: function () {
    if (this.$route.path === "/search" && this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.pos == "定位中") {
      setTimeout(() => {
        if (window.returnCitySN) {
          var pos = window.returnCitySN.cname;
          sessionStorage.setItem("topnav_pos", pos);
          this.pos = pos;
        }
      }, 2000);
    }

    if (this.username && (!this.hide || this.hide.indexOf("shopcart") === -1))
      this.loadCart();

    if (!this.hide || this.hide.indexOf("nav") === -1) {
      api
        .all([this.loadClassify()])
        .then(
          api.spread(() => {
            this.loading = false;
          })
        )
        .catch((err) => {
          console.log(err);
        });
    }
  },

  methods: {
    loadClassify: function () {
      return api.post(apis.common.classify, {}).then((res) => {
        this.classify = res.classifyBar;
        this.hotSearch = res.hotsearch;
      });
    },

    loadCart: function () {
      return api
        .post(apis.common.shoppingCart, {})
        .then((res) => {
          this.cart = res;
          this.cartNum = 0;
          res.map((item) => {
            this.cartNum += item.goodsNum;
          });
        })
        .catch(() => {
          this.cart = [];
        });
    },

    search() {
      //this.showClassify = false;
      this.$router.push({ path: "/search", query: { keyword: this.keyword } });
    },

    removeCart(e) {
      const i = e.currentTarget.dataset.i;
      const goods = this.cart[i];
      api
        .post(apis.cart.del, {
          shoppingCartIds: [goods.shoppingCartId],
        })
        .then(() => {
          this.loadCart();
        });
    },

    signout() {
      return api.post(apis.common.signout, {}).then(() => {
        this.$store.dispatch("clearUserData");
        this.$router.push({ path: "/signin" });
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./header.less";
</style>
